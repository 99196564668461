<template>
    <b-tr>
        <b-td>
            {{ name }} - {{ appointment.calendlyEvent.name }}
        </b-td>
        <b-td>
            {{ startTime }}
        </b-td>
        <b-td>
            <b-button v-if="inFuture" variant="danger" @click="confirmDelete" class="mr-2" v-b-tooltip.hover title="Delete this teaching session">
                <b-icon icon="calendar-x" /> Delete
            </b-button>
            <b-button v-if="inFuture && appointment.calendlyInvitee.reschedule_url" class="mr-2" variant="primary" @click="emit('reschedule', appointment.calendlyInvitee.reschedule_url)" v-b-tooltip.hover title="Change the date and time for this teaching session">
                <b-icon icon="calendar2" /> Reschedule
            </b-button>
            <b-button v-if="connectLinkActive" variant="success" :href="`${config.public.irisConnectUrl}/t/${appointment.inviteeId}?ts=${appointment.startTime.toISOString()}`" target="_blank" v-b-tooltip.hover title="Launch zoom for this teaching session">
                <b-icon icon="camera-video" /> Launch Event
            </b-button>
        </b-td>
    </b-tr>
</template>
<script lang="ts" setup>
import { AppointmentSlotInterface } from '@/server/models/appointmentSlot'
// import type { PropType } from 'vue'
import { DateTime, Interval } from 'luxon'

    const instance = getCurrentInstance()
    const props = defineProps<{
        appointment: AppointmentSlotInterface,
        name: string,
        showConnectLink?: boolean
    }>()

    const emit = defineEmits<{
        (name: 'delete'): void,
        (name: 'reschedule', url: string): void
    }>()

    const config = useRuntimeConfig()

    const startTime = computed(() => {
        return DateTime.fromJSDate(props.appointment.startTime).setZone('Europe/London').toFormat(`cccc, d LLLL yyyy 'at' h:mm a`, {
            locale: 'en-GB'
        })
    })

    const inFuture = computed(() => {
        return props.appointment.startTime > new Date()
    })

    const connectLinkActive = computed(() => {
        return props.showConnectLink && Interval.fromDateTimes(DateTime.now().minus({ minutes: 25 }), DateTime.now().plus({ day: 1 })).contains(DateTime.fromJSDate(props.appointment.startTime))
    })

    const confirmDelete = () => {
        return instance.proxy.$bvModal.msgBoxConfirm('Are you sure you want to delete this booking?').then((resp) => {
            if(resp) {
                return emit('delete')
            }
        })
    }
    
</script>